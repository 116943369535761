<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="view-form">
      <v-container>
        <v-row justify="center" class="mt-2">
          <template v-if="loadingImage && !noimage">
            <div class="noimage-wrapper">
              <v-progress-circular
                indeterminate
                color="blue"
              ></v-progress-circular>
            </div>
          </template>
          <template v-else-if="!loadingImage && !noimage && logo != null">
            <v-img max-height="100" contain max-width="450" :src="logo"></v-img>
          </template>
          <template v-else>
            <div class="noimage-wrapper noimage">{{ companyName }}</div>
          </template>
        </v-row>
        <v-row class="form_leadt">
          <h1 class="title">{{ companyName }}</h1>
          <p>Please answer questions & submit photos for doctor evaluation</p>
        </v-row>
        <div class="d-flex flex-column form_lead">
          <v-form ref="registerForm" v-model="formValid">
            <div class="field">
              <v-text-field
                v-model="newLead.name"
                rounded
                :rules="[rules.required]"
                label="Name"
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="field">
              <v-text-field
                rounded
                v-model="newLead.last_name"
                label="Last Name"
                :rules="[rules.required]"
                outlined
                dense
              ></v-text-field>
            </div>

            <v-row class="field">
              <v-col cols="12" sm="4">
                <v-text-field
                  rounded
                  prepend-inner-icon="mdi-numeric-positive-1"
                  label="Phone"
                  :rules="[rules.isPhone]"
                  v-model="newLead.phone"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  rounded
                  v-model="newLead.email"
                  :rules="[rules.email]"
                  label="Email"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
              <template v-if="formComposition.gender.active">
                <v-col cols="12" sm="4">
                  <v-radio-group
                    class="my-2 d-flex justify-center"
                    v-model="newLead.isMale"
                    row
                  >
                    <v-radio
                      :label="formComposition.gender.label"
                      :value="false"
                    ></v-radio>
                    <v-radio
                      :label="formComposition.gender.label1"
                      :value="true"
                    ></v-radio> </v-radio-group
                ></v-col>
              </template>
            </v-row>
            <v-row class="field">
              <template v-if="formComposition.dob.active">
                <v-col cols="12" sm="3"
                  ><ma-date-picker
                    v-model="newLead.birthDate"
                    past
                    :label="formComposition.dob.label"
                    :editable="true"
                /></v-col>
              </template>
              <template v-if="formComposition.weight.active">
                <v-col cols="12" sm="3">
                  <v-text-field
                    :label="formComposition.weight.label"
                    :rules="[rules.required]"
                    v-model="newLead.weight"
                    outlined
                    dense
                  ></v-text-field></v-col
              ></template>
              <template v-if="formComposition.height.active">
                <v-col
                  class="d-flex flex-column align-center heightfield"
                  cols="12"
                  sm="6"
                >
                  <label
                    >{{ formComposition.height.label }}:
                    <strong>
                      {{ heightfeet + "'." + heightinch + '"' }}</strong
                    ></label
                  >
                  <div class="d-flex mt-1">
                    <v-text-field
                      class="mx-1"
                      :label="formComposition.height.label1"
                      :rules="[rules.required]"
                      v-model="heightfeet"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="mx-1"
                      :label="formComposition.height.label2"
                      :rules="[rules.required]"
                      v-model="heightinch"
                      outlined
                      dense
                    ></v-text-field>
                  </div> </v-col
              ></template>
            </v-row>
            <v-row class="my-2">
              <template v-if="formComposition.doctorChoise.active">
                <v-col cols="12" sm="6">
                  <div class="field">
                    <v-autocomplete
                      :label="formComposition.doctorChoise.label"
                      outlined
                      v-model="newLead.doctorChoiceUuid"
                      :rules="[rules.required]"
                      rounded
                      :items="doctors"
                      item-text="fullname"
                      item-value="uuid"
                      dense
                    ></v-autocomplete>
                  </div>
                </v-col>
              </template>

              <template v-if="formComposition.procedureInterest.active">
                <v-col cols="12" sm="6">
                  <div class="field">
                    <v-autocomplete
                      :label="formComposition.procedureInterest.label"
                      outlined
                      v-model="newLead.procedureInterest"
                      :rules="[rules.required]"
                      rounded
                      :items="proceduresform"
                      item-text="name"
                      item-value="name"
                      dense
                    ></v-autocomplete>
                  </div>
                </v-col>
              </template>
            </v-row>

            <template v-if="formComposition.medicalCondition.active">
              <div class="field">
                <v-text-field
                  :label="formComposition.medicalCondition.label"
                  outlined
                  v-model="newLead.medicalCondition"
                  :rules="[rules.required]"
                  rounded
                  dense
                ></v-text-field>
              </div>
            </template>
            <template v-if="formComposition.medications.active">
              <div class="field">
                <v-text-field
                  :label="formComposition.medications.label"
                  outlined
                  v-model="newLead.currentMedications"
                  rounded
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </div>
            </template>
            <template v-if="formComposition.alergies.active">
              <div class="field">
                <v-textarea
                  :label="formComposition.alergies.label"
                  outlined
                  v-model="newLead.alergies"
                  auto-grow
                  rows="1"
                  row-height="45"
                  :rules="[rules.required]"
                ></v-textarea>
              </div>
            </template>
            <template v-if="formComposition.bloodClots.active">
              <div class="field">
                <label>{{ formComposition.bloodClots.label }}</label>
                <v-textarea
                  outlined
                  v-model="newLead.bloodClotsOrClotting"
                  :rules="[rules.required]"
                  auto-grow
                  rows="1"
                  row-height="65"
                  :label="formComposition.bloodClots.label2"
                  value=""
                ></v-textarea>
              </div>
            </template>
            <template v-if="formComposition.previousSurgery.active">
              <div class="field">
                <label for="">{{
                  formComposition.previousSurgery.label
                }}</label>
                <v-radio-group v-model="previousProc" row>
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
                <template
                  v-if="previousProc && formComposition.previousSurgery.active"
                >
                  <v-text-field
                    outlined
                    rounded
                    dense
                    v-model="newLead.previosSurgery"
                  >
                    <template slot="label">
                      <span>{{ formComposition.previousSurgery.label2 }}</span>
                    </template>
                  </v-text-field>
                </template>
              </div>
            </template>
            <v-row class="field">
              <template v-if="formComposition.buttshots.active">
                <v-col cols="12" sm="6">
                  <v-switch
                    inset
                    :label="formComposition.buttshots.label"
                    v-model="newLead.buttShotsInjections"
                  ></v-switch>
                </v-col>
              </template>
              <template v-if="formComposition.children.active">
                <v-col cols="12" sm="6">
                  <v-switch
                    inset
                    :label="formComposition.children.label"
                    v-model="newLead.children"
                  ></v-switch>
                </v-col>
              </template>

              <template v-if="formComposition.smoke.active">
                <v-col cols="12" sm="6">
                  <v-switch
                    inset
                    :label="formComposition.smoke.label"
                    v-model="newLead.smoking"
                  ></v-switch>
                </v-col>
              </template>

              <template v-if="formComposition.alcohol.active">
                <v-col cols="12" sm="6">
                  <v-switch
                    inset
                    :label="formComposition.alcohol.label"
                    v-model="newLead.alcohol"
                  ></v-switch>
                </v-col>
              </template>

              <template v-if="formComposition.other.active">
                <v-col cols="12" sm="6">
                  <v-switch
                    inset
                    :label="formComposition.other.label"
                    v-model="otherSubs"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6">
                  <template v-if="otherSubs">
                    <v-textarea
                      outlined
                      auto-grow
                      rows="1"
                      row-height="65"
                      :label="formComposition.other.label2"
                      v-model="newLead.otherSubstances"
                    ></v-textarea>
                  </template>
                </v-col>
              </template>
            </v-row>

            <v-row class="field">
              <template v-if="formComposition.desireSurgeryDate.active">
                <v-col cols="12" sm="6">
                  <v-select
                    :items="desireSurgery"
                    item-value="value"
                    v-model="newLead.desiredSurgeryDate"
                    :rules="[rules.required]"
                  >
                    <template slot="label">
                      <p class="labels">
                        {{ formComposition.desireSurgeryDate.label }}
                      </p>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-chip small dark :class="item.color">
                        {{ item.label }}
                      </v-chip>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-chip small dark :class="item.color">
                        {{ item.label }}
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
              </template>
              <template v-if="formComposition.source.active">
                <v-col cols="12" sm="6">
                  <v-select
                    :items="howknows"
                    item-text="name"
                    :rules="[rules.required]"
                    item-value="name"
                    v-model="newLead.howKnow"
                  >
                    <template slot="label">
                      <p class="labels1">{{ formComposition.source.label }}</p>
                    </template>
                  </v-select></v-col
                >
              </template>
            </v-row>
            <div class="field d-flex justify-space-between">
              <template v-if="formComposition.details.active">
                <div class="col-sm-6 notes">
                  <v-textarea
                    outlined
                    auto-grow
                    rows="1"
                    row-height="65"
                    :label="formComposition.details.label"
                    v-model="newLead.details"
                  ></v-textarea>
                </div>
              </template>
              <template v-if="formComposition.photo.active">
                <div class="col-sm-6 files">
                  <template>
                    <v-file-input
                      v-model="files"
                      placeholder="Attach files"
                      :label="formComposition.photo.label2"
                      multiple
                      prepend-icon="mdi-paperclip"
                    >
                    </v-file-input>
                    <template v-if="filelist.length != 0">
                      <div class="images">
                        <div v-for="(file, i) in filelist" :key="i">
                          <v-img
                            class="ml-1 imga"
                            max-height="50"
                            max-width="100"
                            :src="file"
                          >
                            <v-btn
                              fab
                              color="error"
                              small
                              class="btn-close"
                              @click="deleteImage(i)"
                              ><v-icon small>mdi-close</v-icon></v-btn
                            >
                          </v-img>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
            </div>
            <template v-if="formComposition.besttime.active">
              <div class="field">
                <label for="">{{ formComposition.besttime.label }}</label>
                <p class="text--secondary">
                  {{ formComposition.besttime.label2 }}
                </p>
                <v-text-field
                  v-model="newLead.bestTimeToCall"
                  :rules="[rules.required]"
                  rounded
                  outlined
                  dense
                ></v-text-field>
              </div>
            </template>
            <template v-if="formComposition.emailme.active">
              <div :class="newLead.emailMe ? 'emailresp field' : 'field'">
                <v-checkbox
                  :label="formComposition.emailme.label"
                  v-model="newLead.emailMe"
                ></v-checkbox>
                <template v-if="newLead.emailMe">
                  <p class="text--secondary">
                    {{ formComposition.emailme.label2 }}
                  </p>
                </template>
              </div>
            </template>
            <template>
              <div class="field">
                <vue-recaptcha
                  sitekey="6Ld_m9MjAAAAAIxWIqch5JoszhvY_XA1J7aM_H_k"
                  @verify="verifyMethod"
                  @expired="expiredMethod"
                  ref="recaptchaComp"
                ></vue-recaptcha>
              </div>
            </template>

            <div class="field">
              <v-btn
                color="primary"
                :disabled="
                  !formValid || loading || invalidAdd || tokenVerify == null
                "
                @click="register"
                class="my-2"
                :loading="loading"
                >Submit</v-btn
              >
              <p class="text--secondary">
                Never submit passwords through this form. Report malicious form
              </p>
            </div>
          </v-form>
        </div>
      </v-container>
    </div>
    <div class="footerForm">
      <div>
        <span>
          Copyright &copy; {{ year }} {{ companyName }}, {{ companyAddress }},
          {{ companyCity }}, {{ companyState }}, {{ mailingZip }}</span
        >
      </div>
      <div class="my-1">
        <a :href="'mailto:' + companyEmail">{{ companyEmail }}</a>
      </div>
      <div>
        <span>{{ $t("phone") }}: {{ companyPhone }} </span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { axiosBase } from "@/api/axios-base";
import moment from "moment";
import rules from "@/components/account/rules";
import { notifyError } from "@/components/Notification";
import { desireSurgery } from "@/utils/consts";
import axios from "axios";
import { apiBaseUrl } from "@/enviorment";
import { mapMutations, mapState } from "vuex";
import { VueRecaptcha } from "vue-recaptcha";

export default Vue.extend({
  name: "external-lead",
  components: { MaDatePicker, VueRecaptcha },
  data() {
    return {
      tokenVerify: null,
      companyName: "",
      formValid: false,
      companyState: "",
      companyCity: "",
      companyAddress: "",
      companyPhone: "",
      companyEmail: "",
      mailingZip: "",
      res: null,
      desireSurgery,
      loading: false,
      loadingImage: false,
      logo: null,
      noimage: false,
      files: [],
      filelist: [],
      otherSubs: false,
      rules,
      proceduresform: [],
      howknows: [],
      doctors: [],
      heightinch: 0,
      heightfeet: 0,
      newLead: {
        name: "",
        last_name: "",
        birthDate: "",
        phone: "",
        isMale: false,
        email: "",
        procedureInterest: "",
        doctorChoiceUuid: "",
        weight: 0,
        height: 0,
        medicalCondition: "",
        currentMedications: "",
        alergies: "",
        bloodClotsOrClotting: "",
        previosSurgery: "",
        buttShotsInjections: false,
        alcohol: false,
        smoking: false,
        otherSubstances: "",
        children: false,
        desiredSurgeryDate: "",
        details: "",
        howKnow: "",
        bestTimeToCall: "",
        emailMe: false,
      },
      previousProc: false,
      formComposition: {
        sourceDefault: null,
        dob: {
          name: "dob",
          label: "Date of Birth",
          active: true,
        },
        weight: {
          name: "weight",
          label: "Weight (lbs)",
          active: true,
        },
        height: {
          name: "height",
          label: "Height",
          label1: "Feet",
          label2: "Inch",
          active: true,
        },
        gender: {
          name: "gender",
          label: "Female",
          label1: "Male",

          active: true,
        },
        doctorChoise: {
          name: "doctorChoise",
          label: "Doctor of Choice",
          active: true,
        },
        procedureInterest: {
          name: "procedureInterest",
          label: "Procedure of Interest",
          active: true,
        },
        medicalCondition: {
          name: "medicalCondition",
          label: "Do you have any medical conditions?",
          active: true,
        },
        medications: {
          name: "medications",
          label: "Do you take any medications?",
          active: true,
        },
        alergies: {
          name: "alergies",
          label: "Alergies",
          active: true,
        },
        bloodClots: {
          name: "bloodClots",
          label: "Have you ever had any blood clots/ blood clotting?",
          label2: "If yes, please describe the situation and when it happened",
          active: true,
        },
        previousSurgery: {
          name: "previousSurgery",
          label: "Any procedures or surgeries in the last 3 months?",
          label2: "Please specify.",
          active: true,
        },
        buttshots: {
          name: "buttshots",
          label: "Do you have butt shots/ injections?",
          active: true,
        },
        children: {
          name: "children",
          label: "Do you have Children?",
          active: true,
        },
        smoke: {
          name: "smoke",
          label: "Do you smoke or use any nicotine products?",
          active: true,
        },
        alcohol: {
          name: "alcohol",
          label: "Do you drink alcohol?",
          active: true,
        },
        other: {
          name: "other",
          label: "Other Substances",
          label2: "Substances",
          active: true,
        },
        desireSurgeryDate: {
          name: "desireSurgeryDate",
          label: "Desired Surgery Date",
          active: true,
        },
        source: {
          name: "source",
          label: "Please tell us how you heard about us",
          active: true,
        },
        details: {
          name: "details",
          label: "Notes",
          active: true,
        },
        photo: {
          name: "photo",
          label: "Photos",
          label1: "Attach files",
          active: true,
        },
        besttime: {
          name: "besttime",
          label: "Best time to call",
          label1: "(office hours 9am- 6pm, M-F)",
          active: true,
        },
        emailme: {
          name: "emailme",
          label: "Email me a copy of my responses.",
          label1:
            "We’ll only use your email address to send you your responses. Once you submit your responses, you’ll be sent to our sign-up page.",
          active: true,
        },
      },
    };
  },
  computed: {
    ...mapState(["configForm", "defaultFormId"]),
    year() {
      const today = moment().format("YYYY");
      return today;
    },
    invalidAdd() {
      if (this.previousProc && this.newLead.previosSurgery == "") {
        return true;
      }
      return false;
    },
  },
  watch: {
    files(val) {
      this.filelist = val.map((file) => {
        return URL.createObjectURL(file);
      });
    },
  },
  methods: {
    ...mapMutations(["destroyToken"]),
    deleteImage(index) {
      this.files.splice(index, 1);
      this.filelist.splice(index, 1);
    },
    getLogo() {
      this.loadingImage = true;
      this.noimage = false;
      const url = `${apiBaseUrl}/minio/getLogo`;
      axios({
        method: "get",
        url: url,
        responseType: "blob",
      })
        .then((res) => {
          const buffer = new Blob([res.data]);
          const file_path = URL.createObjectURL(buffer);
          this.logo = file_path;
          this.noimage = false;
          this.loadingImage = false;
        })
        .catch((error) => {
          this.noimage = true;
          this.loadingImage = false;
        });
    },

    getCompanyData() {
      axiosBase.get("/companies/getCompanyInfo").then((res) => {
        this.companyName = res.data.name ? res.data.name : "";
        this.companyAddress = res.data.address ? res.data.address : "";
        this.companyState = res.data.mailingCity
          ? res.data.mailingCity.state.name
          : "";
        this.companyCity = res.data.mailingCity
          ? res.data.mailingCity.name
          : "";
        this.companyPhone = res.data.phone ? res.data.phone : "";
        this.companyEmail = res.data.email ? res.data.email : "";
        this.mailingZip = res.data.mailingZip ? res.data.mailingZip : "";
      });
    },
    getNomenclators() {
      axiosBase.get("/procedure-form-nom/list").then((res) => {
        this.proceduresform = res.data;
      });
      axiosBase.get("/users/getDoctors").then((res) => {
        this.doctors = res.data;
      });
      axiosBase.get("/patient/getNomenclatorsForRegisterLead").then((res) => {
        this.howknows = res.data.howKnows;
      });
    },
    uploadImages() {
      const url = "/social/uploadPictureforRegistered/" + this.res.lead.uuid;
      const token = this.res.tempToken;
      const axiosRequest = axios.create({
        baseURL: apiBaseUrl,
      });
      axiosRequest.interceptors.request.use(
        (config) => {
          if (token) config.headers.Authorization = "Bearer " + token;

          return config;
        },
        (err) => {
          Promise.reject(err);
        }
      );
      this.loading = true;
      this.files.forEach((element, i) => {
        const formDataPhotoId = new FormData();

        formDataPhotoId.append("picture", element);
        axiosRequest
          .post(url, formDataPhotoId)
          .then(() => {
            if (i == this.files.length - 1) {
              this.clearForm();
            }
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.loading = false;
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      });
    },
    clearForm() {
      this.$refs.registerForm.reset();
      this.res = null;
      this.otherSubs = false;
      this.loading = false;
      this.newLead = {
        name: "",
        last_name: "",
        birthDate: "",
        phone: "",
        email: "",
        doctorChoiceUuid: "",
        procedureInterest: "",
        weight: 0,
        height: 0,
        medicalCondition: "",
        currentMedications: "",
        bloodClotsOrClotting: "",
        previosSurgery: "",
        buttShotsInjections: false,
        alcohol: false,
        smoking: false,
        otherSubstances: "",
        children: false,
        desiredSurgeryDate: "",
        details: "",
        howKnow: "",
        bestTimeToCall: "",
        emailMe: false,
      };

      setTimeout(() => {
        this.$router.push({ path: "/thank" });
      }, 200);
    },
    register() {
      this.loading = true;
      let body = Object.assign({}, this.newLead);
      body.birthDate = moment(body.birthDate).toISOString();
      body.phone = "+1" + body.phone;
      body.height = Number(this.heightfeet + "." + this.heightinch);
      body.weight = Number(body.weight);
      if (!this.otherSubs) {
        body.otherSubstances = "";
      }
      if (!this.formComposition.source.active) {
        body.howKnow = this.formComposition.sourceDefault
          ? this.formComposition.sourceDefault
          : "none";
      }

      body = { ...body, "g-recaptcha-response": this.tokenVerify };
      body = this.cleanNull(body);

      axiosBase
        .post("/social/registerLead", body)
        .then((res) => {
          if (this.files.length != 0 && res.data != undefined) {
            this.res = res.data;
            this.uploadImages();
          } else {
            this.clearForm();
          }
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response?.data?.message.toString();
          this.$refs.recaptchaComp.reset();
          this.tokenVerify = null;
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    resetForm() {
      this.formComposition = {
        sourceDefault: null,
        dob: {
          name: "dob",
          label: "Date of Birth",
          active: true,
        },
        weight: {
          name: "weight",
          label: "Weight (lbs)",
          active: true,
        },
        height: {
          name: "height",
          label: "Height",
          label1: "Feet",
          label2: "Inch",
          active: true,
        },
        gender: {
          name: "gender",
          label: "Female",
          label1: "Male",

          active: true,
        },
        doctorChoise: {
          name: "doctorChoise",
          label: "Doctor of Choice",
          active: true,
        },
        procedureInterest: {
          name: "procedureInterest",
          label: "Procedure of Interest",
          active: true,
        },
        medicalCondition: {
          name: "medicalCondition",
          label: "Do you have any medical conditions?",
          active: true,
        },
        medications: {
          name: "medications",
          label: "Do you take any medications?",
          active: true,
        },
        alergies: {
          name: "alergies",
          label: "Alergies",
          active: true,
        },
        bloodClots: {
          name: "bloodClots",
          label: "Have you ever had any blood clots/ blood clotting?",
          label2: "If yes, please describe the situation and when it happened",
          active: true,
        },
        previousSurgery: {
          name: "previousSurgery",
          label: "Any procedures or surgeries in the last 3 months?",
          label2: "Please specify.",
          active: true,
        },
        buttshots: {
          name: "buttshots",
          label: "Do you have butt shots/ injections?",
          active: true,
        },
        children: {
          name: "children",
          label: "Do you have Children?",
          active: true,
        },
        smoke: {
          name: "smoke",
          label: "Do you smoke or use any nicotine products?",
          active: true,
        },
        alcohol: {
          name: "alcohol",
          label: "Do you drink alcohol?",
          active: true,
        },
        other: {
          name: "other",
          label: "Other Substances",
          label2: "Substances",
          active: true,
        },
        desireSurgeryDate: {
          name: "desireSurgeryDate",
          label: "Desired Surgery Date",
          active: true,
        },
        source: {
          name: "source",
          label: "Please tell us how you heard about us",
          active: true,
        },
        details: {
          name: "details",
          label: "Notes",
          active: true,
        },
        photo: {
          name: "photo",
          label: "Photos",
          label1: "Attach files",
          active: true,
        },
        besttime: {
          name: "besttime",
          label: "Best time to call",
          label1: "(office hours 9am- 6pm, M-F)",
          active: true,
        },
        emailme: {
          name: "emailme",
          label: "Email me a copy of my responses.",
          label1:
            "We’ll only use your email address to send you your responses. Once you submit your responses, you’ll be sent to our sign-up page.",
          active: true,
        },
      };
    },

    initForm() {
      if (this.configForm.length != 0) {
        const name = this.$route.params.nameForm;

        const formconf = this.configForm.find((fr) => fr.url == name);
        if (formconf) {
          const temp = JSON.parse(formconf.value);
          this.formComposition = temp;
        } else {
          if (this.defaultFormId) {
            const uuid = this.defaultFormId.value;
            const f = this.configForm.find((cf) => cf.uuid == uuid);
            if (f) {
              const temp = JSON.parse(f.value);
              this.formComposition = temp;
            } else {
              const temp = JSON.parse(this.configForm[0].value);
              this.formComposition = temp;
            }
          } else {
            const temp = JSON.parse(this.configForm[0].value);
            this.formComposition = temp;
          }
        }
      } else {
        this.resetForm();
      }
    },

    verifyMethod(event) {
      this.tokenVerify = event;
    },
    expiredMethod() {
      this.tokenVerify = null;
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  async mounted() {
    this.destroyToken();
    await this.initForm();
    await this.getNomenclators();
    this.getCompanyData();
    await this.getLogo();
  },
});
</script>
<style lang="scss" scoped>
.labels {
  font-size: 16px;
}
.labels1 {
  font-size: 14px;
}
.form_lead {
  max-width: 42rem;

  margin: 25px auto;
}
.form_leadt {
  max-width: 42rem;
  display: block;
  margin: 25px auto;
  padding: 0 15px;
}
.imga {
  position: relative;
}
.emailresp {
  background-color: #ffeab6;
}
.field {
  padding: 0 15px;
}
.heightfield {
  margin-top: -27px !important;
}
.btn-close {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  right: 0;
}
.images {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}
.title {
  font-style: inherit;
  font-weight: 500;
  font-feature-settings: "calt" 0;
  color: #333;
  font-size: 28px;
  display: block;
  line-height: 36px;
  margin-bottom: 6px;
  margin-top: 25px;
  word-wrap: break-word;
}
.notes {
  margin-top: -5px;
  margin-left: -15px;
}
.files {
  margin-top: -20px;
  margin-left: 25px !important;
}
.field-checks {
  max-width: 42rem;
  background-color: #ffffff;
}

.footerForm {
  height: 124px;
  border-top: 1px solid rgb(184, 181, 181);
  background: #ccc;
  text-align: center;
  padding: 25px;
  display: block;
}

.noimage-wrapper {
  display: flex;
  max-height: 100px;
}
.noimage {
  border: 1px solid rgb(184, 181, 181);
  height: 50px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
  font-weight: 600;
}
</style>

<i18n>
{
  "en": {
    "message": "Page not found",
    "btn": "Go home",
    "phone":"Phone",
    "email":"Email"
    
  },
  "es": {
    "message": "Página no encontrada",
    "btn": "Ir al inicio",
    "phone":"Telefono",
    "email":"Correo"
  }
}
</i18n>
