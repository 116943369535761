<template>
  <div class="thank_texts">
    <span class="thank"
      >Thank you for your interest. A coordinator will reach out to you
      soon!</span
    >
  </div>
</template>
<script>
export default {
  name: "thank-view",
};
</script>
<style lang="scss" scoped>
.thank {
  font-size: 24px;
}
.btn-thank {
  margin-top: 45px;
}
.thank_texts {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}
</style>
